import { useState } from "react";
import "./styles.css";
import { Home } from "./pages/Home";
import { BookFlight } from "./pages/BookFlight";
import { ManageSchedules } from "./pages/ManageSchedules";
import { FlightReports } from "./pages/FlightReports";
import { RouteDetails } from "./pages/RouteDetails";
import { Stats } from "./pages/Stats";
import { Login } from "./pages/Login";
import { Register } from "./pages/Register";
import { Reset } from "./pages/Reset";
import { RequestReset } from "./pages/RequestReset";
import { RouteChangelog } from "./pages/RouteChangelog";
import { Contact } from "./pages/Contact";
import { Profile } from "./pages/Profile";
import { Route, Switch } from "react-router-dom";
import { UserContext } from "./UserContext";

import { ProtectedRoute, RedirectAuth } from "./Protected";
import { Page404 } from "./pages/404";
import { Roster } from "./pages/Roster";

import { handleSSOResponse } from "./middleware/auth";
import { Pbs } from "./pages/Pbs";

const SSORedirect = () => {
  const queryString = window.location.search
  const urlParams = new URLSearchParams(queryString)
  const token = urlParams.get('s')
  if (token) {
    handleSSOResponse(token);
  }
}

export default function App() {
  const [userContextData, setUserContextData] = useState(null)
  SSORedirect();
  return (
    <div className="App">
      <UserContext.Provider value={{ userContextData, setUserContextData }}>
        <Switch>
          <Route path="/" exact component={Home} />
          <RedirectAuth path="/login" exact component={Login} />
          <RedirectAuth path="/register" exact component={Login} />
          <Route path="/route/changelog" exact component={RouteChangelog} />
          <Route path="/reset-request" exact component={RequestReset} />
          <Route path="/reset/:token" exact component={Reset} />
          <Route path="/contact" exact component={Contact} />
          <Route path="/roster" exact component={Roster} />
          <ProtectedRoute path="/book" exact component={BookFlight} />
          <ProtectedRoute path="/manage" exact component={ManageSchedules} />
          <ProtectedRoute path="/pbs" exact component={Pbs} />
          <ProtectedRoute path="/flights" exact component={FlightReports} />
          <ProtectedRoute path="/stats" exact component={Stats} />
          <ProtectedRoute path="/profile/:username" exact component={Profile} />
          <ProtectedRoute path="/route/detail/:id" exact component={RouteDetails} />
          <Route component={Page404} />
        </Switch>
      </UserContext.Provider>
    </div>
  );
}
