// export const API_URL = "http://67.4.212.216:8001";

export let API_URL = process.env.NODE_ENV === 'development' ?
    //'http://localhost:8787/'
    'https://api.rotate.group/'
    : 'https://api.rotate.group/'

export const SSO_URL = 'https://sso.rotate.group/';

export const CARRIER_ID = "de175f0e-6545-4fc0-ad70-43179cec5318";
