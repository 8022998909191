import React from "react";
import { Link } from "react-router-dom";

interface Props {
  signedIn: boolean;
}

import "./hero.scss";

export const Hero: React.FC<Props> = (props) => {
  return (
    <div className="uk-section m-60vh">
      <div className="uk-container">
        <div className="uk-grid" uk-grid="true">
          <div className="uk-margin-xlarge-bottom uk-margin-xlarge-top uk-margin-remove-topt@m uk-margin-remove-bottom@m uk-width-1-3@l uk-width-1-2@xl uk-text-center uk-text-left@l uk-text-left@xl uk-first-column">
            <div className="uk-inline uk-width-xlarge">
              <h1 className="uk-h6 uk-margin-remove uk-light">
                Welcome to FlyvAmerican
              </h1>
              <h2 className="uk-h1 uk-margin-top uk-margin-medium-bottom uk-light">
                A remarkable community and flight simulator experience
              </h2>
              <div className="uk-grid-medium uk-flex-middle uk-flex-inline uk-grid">
                <div className="uk-first-column">
                  {!props.signedIn ? (
                    <Link
                      className="uk-button uk-button-primary uk-button-large"
                      to="/register"
                    >
                      Register
                    </Link>
                  ) : (
                    <Link
                      className="uk-button uk-button-primary uk-button-large"
                      to="/book"
                    >
                      Book Flight
                    </Link>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
