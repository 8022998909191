import React, { useState, useContext, useEffect } from "react";
import { Navbar } from "../components/navbar/Navbar.jsx";
import { HeroSmall } from "../components/hero/HeroSmall";
import { Footer } from "../components/footer/Footer.jsx";
import { Link } from "react-router-dom";
import { getSchedules, createBid } from "../middleware/core";

import { UserContext } from "../UserContext";
import { isAuthenticated, refresh } from "../middleware/auth";
import { getUserInfo } from "../middleware/core";

import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css'
import logo from "../images/logo-white.png";

export const BookFlight = () => {
  const [schedulesInitial, setSchedulesInitial] = useState([])
  const [schedules, setSchedules] = useState([])
  const [arrivalSearch, setArrivalSearch] = useState('');
  const [departureSearch, setDepartureSearch] = useState('');
  const [aircraftTypeSearch, setAircraftTypeSearch] = useState('');
  const [flightTimeGtSearch, setFlightTimeGtSearch] = useState('');
  const [flightTimeLtSearch, setFlightTimeLtSearch] = useState('');
  const [loading, setLoading] = useState(true);
  const [aircraftTypes, setAircraftTypes] = useState([]);

  const { userContextData, setUserContextData } = useContext(UserContext);
  useEffect(() => {
    const getInitialData = async () => {
      if (isAuthenticated()) {
        const user = await refresh();
        if (user) {
          const userInfo = await getUserInfo(user.public_id);
          user.info = userInfo;
          setUserContextData(user);
        }
      }


      const data = await getSchedules({ dep: departureSearch, arr: arrivalSearch, aircraftType: aircraftTypeSearch, flightTimeGt: flightTimeGtSearch, flightTimeLt: flightTimeLtSearch });
      setSchedulesInitial(data);
      setSchedules(data)

      const aircraftSet = new Set();
      const types = data.filter((s) => {
        if (aircraftSet.has(s.aircraft_type)) return false;
        aircraftSet.add(s.aircraft_type);
        return true;
      });
      setAircraftTypes(types);
      setLoading(false);
    }
    getInitialData()
  }, []);

  const convertTime = (blockTimeString) => {
    let decimalTime = parseFloat(blockTimeString);
    decimalTime = decimalTime * 60 * 60;
    let hours = Math.floor((decimalTime / (60 * 60)));
    decimalTime = decimalTime - (hours * 60 * 60);
    let minutes = Math.floor((decimalTime / 60));
    return (`${hours > 0 ? `${hours}hr` : ''} ${minutes > 0 ? `${minutes}min` : ''}`)
  }

  const handleSearch = async (targetValue, searchField) => {

    let data = undefined;
    if (searchField === "dep") {
      if (targetValue.length < 4 && targetValue.length !== 0) return false;
      setDepartureSearch(targetValue);
      data = await getSchedules({ dep: targetValue, arr: arrivalSearch, aircraftType: aircraftTypeSearch, flightTimeGt: flightTimeGtSearch, flightTimeLt: flightTimeLtSearch });
    }
    if (searchField === "arr") {
      if (targetValue.length < 4 && targetValue.length !== 0) return false;
      setArrivalSearch(targetValue);
      data = await getSchedules({ dep: departureSearch, arr: targetValue, aircraftType: aircraftTypeSearch, flightTimeGt: flightTimeGtSearch, flightTimeLt: flightTimeLtSearch });
    }
    if (searchField === "ac") {
      const val = targetValue === 'All Aircraft Types' ? '' : targetValue
      setAircraftTypeSearch(val);
      data = await getSchedules({ dep: departureSearch, arr: arrivalSearch, aircraftType: val, flightTimeGt: flightTimeGtSearch, flightTimeLt: flightTimeLtSearch });
    }
    if (searchField === "fltGt") {
      setFlightTimeGtSearch(targetValue);
      data = await getSchedules({ dep: departureSearch, arr: arrivalSearch, aircraftType: aircraftTypeSearch, flightTimeGt: targetValue, flightTimeLt: flightTimeLtSearch });
    }
    if (searchField === "fltLt") {
      setFlightTimeLtSearch(targetValue);
      data = await getSchedules({ dep: departureSearch, arr: arrivalSearch, aircraftType: aircraftTypeSearch, flightTimeGt: flightTimeGtSearch, flightTimeLt: targetValue });
    }

    if (!data === undefined) {
      data = await getSchedules({ dep: departureSearch, arr: arrivalSearch, aircraftType: aircraftTypeSearch, flightTimeGt: flightTimeGtSearch, flightTimeLt: flightTimeLtSearch });
    }

    setSchedules(data)
  }

  const bookFlight = async (e, id) => {
    e.preventDefault();
    e.persist()
    console.log(e)
    const req = await createBid(id);
    console.log(e)

    if (req.status === "ok") {
      e.target.innerText = 'Flight Booked';
    } else {
      e.target.innerText = 'Already Added';
    }
  }

  return (
    <>
      <div className="section-intro">
        <Navbar user={userContextData} />
        <HeroSmall title="Flight Schedules" subTitle="Crew Center" />
      </div>
      <div className="uk-section uk-padding-medium">
        <div className="uk-container">
          <form className="uk-grid-small uk-grid-match uk-grid uk-margin-medium-bottom" uk-grid="true">
            <div className="uk-grid-small uk-grid-match uk-grid  uk-width-1-1" uk-grid="true">
              <div className="uk-width-1-3@s">
                <input
                  className="uk-input uk-input-large uk-height-1-1"
                  type="text"
                  placeholder="Departure ICAO"
                  onChange={e => handleSearch(e.currentTarget.value, "dep")}
                />
              </div>
              <div className="uk-width-1-3@s">
                <input
                  className="uk-input uk-input-large uk-height-1-1"
                  type="text"
                  placeholder="Arrival ICAO"
                  onChange={e => handleSearch(e.currentTarget.value, "arr")}
                />
              </div>
              <div className="uk-width-auto@s">
                <button
                  className="uk-button uk-button-primary uk-button-large"
                  onClick={e => e.preventDefault()}
                >
                  Search
                </button>
              </div>
            </div>
            <div className="uk-width-1-1">
              <div className="uk-width-auto@s">
                <div className="uk-margin uk-margin-small-right">
                  <select className="uk-select uk-input" onChange={e => handleSearch(e.currentTarget.value, "ac")}>
                    <option value={null} default>All Aircraft Types</option>
                    {
                      aircraftTypes.length > 0 && aircraftTypes.map((type, index) => {
                        return (
                          <option key={index} value={type.aircraft_type}>{type.aircraft_type}</option>
                        )
                      })
                    }
                  </select>
                </div>
              </div>
              <div className="uk-width-auto@s">
                <div className="uk-margin uk-margin-small-right">
                  <select
                    onChange={e => handleSearch(e.currentTarget.value, "fltGt")}
                    className="uk-select uk-input">
                    <option value={null} default>Minimum Flight Time</option>
                    <option>0 hrs</option>
                    <option>1 hrs</option>
                    <option>2 hrs</option>
                    <option>3 hrs</option>
                    <option>4 hrs</option>
                    <option>5 hrs</option>
                    <option>6 hrs</option>
                    <option>7 hrs</option>
                    <option>8 hrs</option>
                    <option>9 hrs</option>

                  </select>
                </div>
              </div>
              <div className="uk-width-auto@s">
                <div className="uk-margin uk-margin-small-right">
                  <select
                    onChange={e => handleSearch(e.currentTarget.value, "fltLt")}
                    className="uk-select uk-input">
                    <option value={null} default>Maximum Flight Time</option>
                    <option>1 hrs</option>
                    <option>2 hrs</option>
                    <option>3 hrs</option>
                    <option>4 hrs</option>
                    <option>5 hrs</option>
                    <option>6 hrs</option>
                    <option>7 hrs</option>
                    <option>8 hrs</option>
                    <option>9 hrs</option>
                    <option>10 hrs</option>
                  </select>
                </div>
              </div>
            </div>
          </form>
          <div className="uk-grid-small" uk-grid="true">
            {schedules.length > 0 ? (
              schedules.map((schedule) => (
                <div className="uk-width-1-1">
                  <div className="uk-card uk-padding uk-card-default">
                    <div className="uk-grid uk-text-left" uk-grid="true">
                      <div className="uk-first-column uk-width-auto">
                        <h4 className="uk-h6 uk-margin-remove">
                          Flight Number
                        </h4>
                        <h3 className="uk-h2 uk-margin-remove sky">{schedule.callsign}{schedule.flight_number}</h3>
                      </div>
                      <div>
                        <h4 className="uk-h6 uk-margin-remove">
                          Departure - Arrival
                        </h4>
                        <h3 className="uk-h2 uk-margin-remove">{schedule.dep_icao} - {schedule.arr_icao}</h3>
                      </div>
                      <div>
                        <h4 className="uk-h6 uk-margin-remove">Equipment</h4>
                        <h3 className="uk-h2 uk-margin-remove">{schedule.aircraft_type}</h3>
                      </div>
                      <div>
                        <h4 className="uk-h6 uk-margin-remove">Flight Time</h4>
                        <h3 className="uk-h2 uk-margin-remove">{convertTime(schedule.est_flight_time)}</h3>
                      </div>
                      <div className="uk-width-1-6 uk-visible@m">
                        <h4 className="uk-h6 uk-margin-remove">Notes</h4>
                        <h3 className="uk-h5 uk-margin-remove">
                          {schedule.notes}
                        </h3>
                      </div>
                      <div className="uk-align-right@l uk-margin-auto-left@l">
                        <Link
                          className="uk-button uk-button-primary"
                          onClick={(e) => bookFlight(e, schedule.public_id)}
                          to=""
                        >
                          Book Flight
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              ))
            ) : (
              <>{!loading ?
                <div className="uk-width-1-1">
                  <div className="uk-card uk-padding uk-card-default uk-card-body uk-text-left@m uk-text-center">
                    <div className="uk-grid" uk-grid="true">
                      <div className="uk-width-auto@m uk-width-1-1">
                        <img style={{ width: '125px' }} className="uk-margin-right@m uk-align-center" src={logo}></img>
                      </div>
                      <div className="uk-width-auto@m uk-width-1-1 uk-margin-auto-top uk-margin-auto-bottom">
                        <h3 className="uk-h1 uk-align-center">Oooops, No flights found.</h3>
                      </div>
                    </div>
                  </div>
                </div> : <></>}
              </>
            )}
            {loading ? (
              <>
                <div className="uk-width-1-1">
                  <Skeleton height={145} />
                </div>
                <div className="uk-width-1-1">
                  <Skeleton height={145} />
                </div>
                <div className="uk-width-1-1">
                  <Skeleton height={145} />
                </div>
                <div className="uk-width-1-1">
                  <Skeleton height={145} />
                </div>
              </>
            ) : <></>}
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};
